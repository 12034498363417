import {Dispatch, FC, SetStateAction, useContext, useEffect, useState} from "react";
import {FireDbContext} from "../firedb/firedb";
import Row from "../grid/row";
import Col from "../grid/col";
import * as firebaseui from "firebaseui";
import firebase from "firebase";
import {StyledFirebaseAuth} from "react-firebaseui";

const firebaseUiConfig: firebaseui.auth.Config = {
    signInSuccessUrl: '/',
    signInOptions: [
        firebase.auth.EmailAuthProvider.PROVIDER_ID
    ]
}

enum LoginState {
    LOGIN_BUTTON,
    LOGIN_FLOW,
    LOGOUT_BUTTON
}

const LoginButton: FC<{ setLoginState: Dispatch<SetStateAction<LoginState>> }> = ({setLoginState}) => {
    const onClick = () => {
        setLoginState(LoginState.LOGIN_FLOW)
    }
    return <div className="container">
        <Row>
            <Col clazz="s12 center-align">
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a className="waves-effect waves-teal btn-flat" onClick={onClick}>Aanmelden</a>
            </Col>
        </Row>
    </div>
}

const LogoutButton: FC<{ auth: firebase.auth.Auth | undefined }> = ({auth}) => {
    const onClick = () => {
        if (auth) {
            auth.signOut()
        }
    }
    return <div className="container">
        <Row>
            <Col clazz="s12 center-align">
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a className="waves-effect waves-teal btn-flat" onClick={onClick}>Afmelden</a>
            </Col>
        </Row>
    </div>
}


const Login: FC = () => {
    const {loggedIn, auth} = useContext(FireDbContext);
    const [loginState, setLoginState] = useState<LoginState>(LoginState.LOGIN_BUTTON)

    useEffect(() => {
        setLoginState(loggedIn ? LoginState.LOGOUT_BUTTON : LoginState.LOGIN_BUTTON)
    }, [loggedIn])

    switch (loginState) {
        case LoginState.LOGIN_BUTTON:
            return <LoginButton setLoginState={setLoginState}/>
        case LoginState.LOGOUT_BUTTON:
            return <LogoutButton auth={auth}/>
    }
    return <StyledFirebaseAuth uiConfig={firebaseUiConfig} firebaseAuth={auth}/>
}

export default Login