import React, {FC} from "react";
import Col from "../../../components/grid/col";
import Collection from "../../../components/collection/collection";
import CollectionHeader from "../../../components/collection/collectionheader";
import CollectionItems from "../../../components/collection/collectionitems";
import CollectionItem, {CollectionItemType} from "../../../components/collection/collectionitem";

const Pricing: FC = () => {
    return (
        <Col clazz="col s12 m6 offset-m3 l4 center">
            <Collection>
                <CollectionHeader title="Prijzen"/>
                <CollectionItems>
                    <CollectionItem type={CollectionItemType.EVEN}
                                    text={<>Prijs afhankelijk van de periode<br/>(zie beschikbaarheid)</>}/>
                    <CollectionItem type={CollectionItemType.ODD}
                                    text="Reductie bij langere periode!"/>
                    <CollectionItem type={CollectionItemType.EVEN}
                                    text="Inclusief verwarming, water, Wifi, garage"/>
                    <CollectionItem type={CollectionItemType.ODD}
                                    text={<>Waarborg: <b>€100</b><br/>Eindschoonmaak: <b>€60</b><br/> (of zelf poetsen)</>}/>
                </CollectionItems>
            </Collection>
        </Col>
    )
}

export default Pricing