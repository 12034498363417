import {FC, useContext} from "react";
import {FireDbContext} from "../firedb/firedb";
import {DayInfo} from "./calendarinfo";
import {getDate, getDay, getMonth, getYear} from "date-fns";
import {css} from "@emotion/css";

const CellCss = {}

const CalendarMatrixBodyCellReadOnly: FC<{ dayInfo: DayInfo | undefined, day: Date }> = ({dayInfo, day}) => {
    const {date} = useContext(FireDbContext);
    const isDateInMonth: boolean = getMonth(day) === getMonth(date)
    const color: string = isDateInMonth ? 'black-text' : 'grey-text text-lighten-4'

    const monthInPast: boolean = (getYear(day) * 100 + getMonth(day)) < (getYear(new Date()) * 100 + getMonth(new Date()))

    if (monthInPast) {
        return <td className={"center-align grey lighten-4 " + color}><span>{getDate(day)}</span></td>
    }
    if (!isDateInMonth) {
        return <td className={"center-align grey lighten-4 " + color}><span>{getDate(day)}</span></td>
    }
    if (dayInfo === undefined) {
        return <td className={css(CellCss) + " center-align green lighten-5 " + color}>
            <span>{getDate(day)}</span>
        </td>
    }
    const occupiedColor: string = monthInPast ? 'grey' : (dayInfo.occupied ? 'red' : 'green')
    return (
        <td className={css(CellCss) + " center-align lighten-5 " + occupiedColor + ' ' + color}>
            {getDay(day) === 6 ? <><span><b>{getDate(day)}</b></span><br/></> : <>
                <span>{getDate(day)}</span></>}
            {getDay(day) === 6 ? <span>{dayInfo.price ? '€' + dayInfo.price : ''}</span> : <></>}
        </td>
    )
}

export default CalendarMatrixBodyCellReadOnly