import {FC} from "react";
import {css} from "@emotion/css";

const ParallaxCss: Record<string, string> = {
    height: "300px"
}

const Parallax: FC<{ source: string, alt: string }> = ({source, alt}) =>
    <div className={css(ParallaxCss) + " parallax-container"}>
        <div className="parallax"><img alt={alt} src={source}/></div>
    </div>

export default Parallax
