import {FC, ReactNode} from "react";
import {css} from "@emotion/css";

const CollectionItemCss: Record<string, string> = {
    "padding": "7px"
}

export enum CollectionItemType {
    EVEN,
    ODD
}

const CollectionItem: FC<{ type: CollectionItemType, text: ReactNode }> = ({type, text}) => {
    switch (type) {
        case CollectionItemType.EVEN:
            return <li className={css(CollectionItemCss) + " grey lighten-4"}>{text}</li>
        case CollectionItemType.ODD:
            return <li className={css(CollectionItemCss)}>{text}</li>
    }
}


export default CollectionItem