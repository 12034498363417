import React, {FC} from "react";
import Col from "../../../components/grid/col";
import Collection from "../../../components/collection/collection";
import CollectionHeader from "../../../components/collection/collectionheader";
import CollectionItems from "../../../components/collection/collectionitems";
import CollectionItem, {CollectionItemType} from "../../../components/collection/collectionitem";

const General: FC = () => {
    return (
        <Col clazz="s12 m6 l4 center">
            <Collection>
                <CollectionHeader title="Algemeen"/>
                <CollectionItems>
                    <CollectionItem type={CollectionItemType.EVEN}
                                    text={<>Weekverhuur<br/><b>zaterdag 14u tot zaterdag 11u</b></>}/>
                    <CollectionItem type={CollectionItemType.ODD}
                                    text="4 Personen"/>
                    <CollectionItem type={CollectionItemType.EVEN}
                                    text="2 Kamers"/>
                    <CollectionItem type={CollectionItemType.ODD}
                                    text="2 terassen"/>
                    <CollectionItem type={CollectionItemType.EVEN}
                                    text="Garage"/>
                    <CollectionItem type={CollectionItemType.ODD}
                                    text="Wifi"/>
                    <CollectionItem type={CollectionItemType.EVEN}
                                    text="Kinderbed, kinderstoel"/>
                    <CollectionItem type={CollectionItemType.ODD}
                                    text="Geen huisdieren toegelaten"/>
                </CollectionItems>
            </Collection>
        </Col>
    )
}

export default General