import React, {FC, ReactElement, useContext} from "react";
import {FireDbContext} from "../firedb/firedb";
import Row from "../grid/row";
import Col from "../grid/col";
import {getMonth, getYear} from "date-fns";

const CalendarFooter: FC = () => {
    const {date, setDate} = useContext(FireDbContext);

    const onClick = () => {
        if (setDate) {
            setDate(new Date())
        }
    }

    const thisMonth: string = getYear(new Date()) + '-' + getMonth(new Date())
    const selectedMonth: string = getYear(date) + '-' + getMonth(date)

    let todayButton: ReactElement = <></>

    if (thisMonth !== selectedMonth) {
        todayButton = <>
            <br/>
            <Row>
                <Col clazz={"s12 center-align"}>
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <a onClick={onClick}
                       className="btn waves-effect waves-light grey">Vandaag</a>
                </Col>
            </Row>
        </>
    }

    return (
        <>
            <br/>
            <span className="new badge red lighten-5 black-text z-depth-3" data-badge-caption="">Bezet</span>
            <span className="new badge green lighten-5 black-text z-depth-3" data-badge-caption="">Vrij</span>
            {todayButton}
        </>
    )
}

export default CalendarFooter