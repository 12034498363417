import React, {FC} from "react";
import Intro from "./intro/intro";

import parallaxImage1 from './../../assets/images/P1000014.jpg'
import parallaxImage2 from './../../assets/images/P1000015.jpg'
import parallaxImage3 from './../../assets/images/P1000025.jpg'
import ParallaxContainer from "../components/parallax/parrallaxcontainer";
import Parallax from "../components/parallax/parallax";
import Info from "./info/info";
import Availability from "./availability/availability";
import Photos from "../components/photos/photos";
import FireDb from "../components/firedb/firedb";
import Login from "../components/login/login";

const Main: FC = () => {
    return (
        <FireDb>
            <main>
                <ParallaxContainer>
                    <Intro/>
                    <Parallax alt={"Appartement Residentie Bolero Westende"} source={parallaxImage1}/>
                    <Info/>
                    <Parallax alt={"Appartement Residentie Bolero Westende"} source={parallaxImage2}/>
                    <Availability/>
                    <Parallax alt={"Appartement Residentie Bolero Westende"} source={parallaxImage3}/>
                    <Photos/>
                    <Login/>
                </ParallaxContainer>
            </main>
        </FireDb>
    )
}

export default Main