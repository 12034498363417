import {FC, useEffect} from "react";
import * as M from 'materialize-css'

const ParallaxContainer: FC = ({children}) => {
    useEffect(() => {
        M.Parallax.init(document.querySelectorAll('.parallax'), {})
    })
    return <>{children}</>
}

export default ParallaxContainer
