import {FC, useContext} from "react";
import {DayInfo} from "./calendarinfo";
import {FireDbContext} from "../firedb/firedb";
import {format, getDate, getMonth} from "date-fns";
import {nlBE} from "date-fns/locale";

const DayEditor: FC<{ dayInfo: DayInfo | undefined, day: Date }> = ({dayInfo, day}) => {
    const {date, database} = useContext(FireDbContext);

    const onLeverClick = (e: any) => {
        const price: number | undefined = (dayInfo ? dayInfo.price : undefined)
        const dateRef: string = format(date, 'yyyy-MM', {locale: nlBE}) + '/days/' + getDate(day)
        if (price) {
            database?.ref(dateRef).set({
                occupied: !e.target.checked,
                price
            })
        } else {
            database?.ref(dateRef).set({
                occupied: !e.target.checked
            })
        }
    }

    const onPriceChange = (e: any) => {
        let price: number | undefined = 0
        try {
            price = parseInt(e.target.value, 10);
            if (isNaN(price)) {
                price = 0
            }
        } catch (e: any) {
            price = 0
        }
        const occupied: boolean = (dayInfo ? dayInfo.occupied : false)
        const dateRef: string = format(date, 'yyyy-MM', {locale: nlBE}) + '/days/' + getDate(day)

        if (price === 0) {
            database?.ref(dateRef).set({
                occupied
            })
        } else {
            database?.ref(dateRef).set({
                occupied,
                price
            })
        }
    }

    let price: string = ''
    let occupiedColor: string = 'green'
    let free: boolean = true

    if (dayInfo) {
        price = dayInfo.price ? dayInfo.price + '' : ''
        occupiedColor = dayInfo.occupied ? 'red' : 'green'
        free = !dayInfo.occupied
    }
    return (
        <td className={"center-align lighten-5 " + occupiedColor}>
            <div className="row">
                <span><b>{getDate(day)}</b></span>
                <form className="col s12">
                    <div className="row">
                        <div className="input-field col s12">
                            <input id={"input_" + day} value={price} type="text"
                                   className="validate center-align" onChange={onPriceChange}/>
                        </div>
                    </div>
                </form>
                <div className="switch">
                    <label>
                        <input type="checkbox" checked={free} onChange={onLeverClick}/>
                        <span className="lever"/>
                    </label>
                </div>
            </div>
        </td>
    )
}

const CalendarMatrixBodyCellEditable: FC<{ dayInfo: DayInfo | undefined, day: Date }> = ({dayInfo, day}) => {
    const {date} = useContext(FireDbContext);

    const isDateInMonth: boolean = getMonth(day) === getMonth(date)
    const color: string = isDateInMonth ? 'black-text' : 'grey-text text-lighten-4'
    if (!isDateInMonth) {
        return <td className={"center-align grey lighten-5 " + color}><span>{getDate(day)}</span></td>
    }
    if (dayInfo) {
        return <DayEditor dayInfo={dayInfo} day={day}/>
    }
    return <DayEditor dayInfo={undefined} day={day}/>
}

export default CalendarMatrixBodyCellEditable