import {FC, useContext} from "react";
import {FireDbContext} from "../firedb/firedb";
import CalendarMatrixBodyCellReadOnly from "./calendarmatrixbodycellreadonly";
import CalendarMatrixBodyCellEditable from "./calendarmatrixbodycelleditable";
import {DayInfo} from "./calendarinfo";

const CalendarMatrixBodyCell: FC<{ dayInfo: DayInfo | undefined, day: Date }> = ({dayInfo, day}) => {
    const {loggedIn} = useContext(FireDbContext);
    return loggedIn
        ? <CalendarMatrixBodyCellEditable dayInfo={dayInfo} day={day}/>
        : <CalendarMatrixBodyCellReadOnly dayInfo={dayInfo} day={day}/>
}

export default CalendarMatrixBodyCell