import React, {FC} from "react";

const CalendarMatrixHeader: FC = () => {
    return (
        <thead>
        <tr className="hide-on-small-and-down">
            <td className="center-align">Maandag</td>
            <td className="center-align">Dinsdag</td>
            <td className="center-align">Woensdag</td>
            <td className="center-align">Donderdag</td>
            <td className="center-align">Vrijdag</td>
            <td className="center-align">Zaterdag</td>
            <td className="center-align">Zondag</td>
        </tr>
        <tr className="hide-on-med-and-up">
            <td className="center-align">Ma</td>
            <td className="center-align">Di</td>
            <td className="center-align">Wo</td>
            <td className="center-align">Do</td>
            <td className="center-align">Vr</td>
            <td className="center-align">Za</td>
            <td className="center-align">Zo</td>
        </tr>
        </thead>
    )
}

export default CalendarMatrixHeader