import {createContext, Dispatch, FC, SetStateAction, useState} from "react";
import {useAuthState} from 'react-firebase-hooks/auth';
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database"

export interface FireDbContextData {
    loggedIn: boolean,
    database?: firebase.database.Database,
    auth?: firebase.auth.Auth,
    date: Date,
    setDate?: Dispatch<SetStateAction<Date>>
}

export const fireDbContextDefaultValue: FireDbContextData = {
    loggedIn: false,
    date: new Date()
}

export const FireDbContext = createContext<FireDbContextData>(fireDbContextDefaultValue);

const firebaseConfig = {
    apiKey: "AIzaSyDDl5aBF-QuhJJKEbPOoP9YXgBYp8JO4qk",
    authDomain: "bolerosmets.firebaseapp.com",
    databaseURL: "https://bolerosmets-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "bolerosmets",
    storageBucket: "bolerosmets.appspot.com",
    messagingSenderId: "783277517414",
    appId: "1:783277517414:web:78b15ed57b3188ec9d1ba7"
}

firebase.initializeApp(firebaseConfig);
const auth: firebase.auth.Auth = firebase.auth()
const db: firebase.database.Database = firebase.database()

const FireDb: FC = ({children}) => {
    const [user] = useAuthState(auth);
    const [date, setDate] = useState<Date>(new Date())
    return (
        <FireDbContext.Provider value={{
            loggedIn: user != null,
            database: db,
            auth,
            date,
            setDate
        }}>
            {children}
        </FireDbContext.Provider>
    )
}

export default FireDb