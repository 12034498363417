import React, {FC} from "react";
import CalendarTitle from "./calendartitle";
import CalendarMatrix from "./calendarmatrix";
import CalendarFooter from "./calendarfooter";

const Calendar: FC = () => {
    return (
        <div className="container center">
            <CalendarTitle/>
            <CalendarMatrix/>
            <CalendarFooter/>
        </div>
    )
}

export default Calendar