import React, {FC, useContext} from "react";
import {addMonths, format} from "date-fns";
import {nlBE} from "date-fns/locale";
import {FireDbContext} from "../firedb/firedb";
import Row from "../grid/row";
import Col from "../grid/col";

const CalendarTitle: FC = () => {
    const {date, setDate} = useContext(FireDbContext);

    const onNextMonthClick = () => {
        if (setDate) {
            setDate(addMonths(date, 1))
        }
    }
    const onPreviousMonthClick = () => {
        if (setDate) {
            setDate(addMonths(date, -1))
        }
    }

    return (
        <>
            <Row>
                <Col clazz={"s3 right-align"}>
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <a onClick={onPreviousMonthClick}
                       className="btn-floating btn-large waves-effect waves-light grey">
                        <i className="material-icons">undo</i>
                    </a>
                </Col>
                <Col clazz={"s6"}>
                    <span><h5>{format(date, 'MMMM yyyy', {locale: nlBE})}</h5></span>
                </Col>
                <Col clazz={"s3 left-align"}>
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <a onClick={onNextMonthClick} className="btn-floating btn-large waves-effect waves-light grey">
                        <i className="material-icons">redo</i>
                    </a>
                </Col>
            </Row>
            <br/>
        </>
    )
}

export default CalendarTitle