import {FC} from "react";
import {css} from "@emotion/css";

const IconsCss: Record<string, string> = {
    position: "relative",
    top: "8px"
}

const ContactItem: FC<{ icon: string, item: string }> = ({icon, item}) =>
    <div className="col s12">
        <i className={css(IconsCss) + " material-icons"}>{icon}</i>{' '}{item}
    </div>

export default ContactItem