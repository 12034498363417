import React, {FC} from "react";
import CalendarMatrixHeader from "./calendarmatrixheader";
import CalendarMatrixBody from "./calendarmatrixbody";

const CalendarMatrix: FC = () => {
    return (
        <table className="z-depth-3">
            <CalendarMatrixHeader/>
            <CalendarMatrixBody/>
        </table>
    )
}

export default CalendarMatrix