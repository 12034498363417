import React, {FC} from "react";
import Col from "../../../components/grid/col";
import Collection from "../../../components/collection/collection";
import CollectionHeader from "../../../components/collection/collectionheader";
import CollectionItems from "../../../components/collection/collectionitems";
import CollectionItem, {CollectionItemType} from "../../../components/collection/collectionitem";

const Rooms: FC = () => {
    return (
        <Col clazz="s12 m6 l4 center">
            <Collection>
                <CollectionHeader title="Kamers"/>
                <CollectionItems>
                    <CollectionItem type={CollectionItemType.EVEN}
                                    text="Leefruimte met volledig ingerichte keuken, Slaapbank (2p), TV, Radio"/>
                    <CollectionItem type={CollectionItemType.ODD}
                                    text="Apart toilet"/>
                    <CollectionItem type={CollectionItemType.EVEN}
                                    text="Badkamer met douche"/>
                    <CollectionItem type={CollectionItemType.ODD}
                                    text="Slaapkamer met 2 persoonsbed"/>
                    <CollectionItem type={CollectionItemType.EVEN}
                                    text="Berging"/>
                </CollectionItems>
            </Collection>
        </Col>
    )
}

export default Rooms