import {FC} from "react";
import ContactItem from "./contactitem";

const Contact: FC = () => {
    return (
        <div className="row">
            <ContactItem icon="phone" item="+32(0)473.17.57.85"/>
            <ContactItem icon="mail" item="rene_smets@skynet.be"/>
        </div>
    )
}

export default Contact