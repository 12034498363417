import React, {FC} from "react";
import Section from "../../components/section/section";
import SectionTitle from "../../components/title/sectiontitle";
import Row from "../../components/grid/row";
import Calendar from "../../components/calendar/calendar";

const Availability: FC = () => {
    return (
        <Section>
            <SectionTitle title="Beschikbaarheid en Prijzen"/>
            <br/>
            <Row>
                <Calendar/>
            </Row>
        </Section>
    )
}

export default Availability