import React, {FC, Fragment, useContext} from "react";
import useCalendarMatrix from "./usecalendarmatrix";
import {nlBE} from "date-fns/locale";
import {format, getDate} from "date-fns";
import CalendarMatrixBodyCell from "./calendarmatrixbodycell";
import {DayInfo, MonthInfo} from "./calendarinfo";
import {useObjectVal} from "react-firebase-hooks/database";
import {FireDbContext} from "../firedb/firedb";
import firebase from "firebase";

const CalendarMatrixBody: FC = () => {
    const {loggedIn, date, database} = useContext(FireDbContext);
    const [calendarMatrix] = useCalendarMatrix(date)

    const dateRef: string = format(date, 'yyyy-MM', {locale: nlBE})
    const ref: firebase.database.Reference | undefined = database?.ref(dateRef);
    const [value] = useObjectVal<MonthInfo>(ref)
    return (
        <tbody>
        {
            calendarMatrix.map((week: Date[]) => {
                const extraRow = loggedIn
                    ? <>
                        <tr className="black">
                            <td colSpan={7}/>
                        </tr>
                    </>
                    : <></>
                return (
                    <Fragment key={week.toString()}>
                        <tr>
                            {
                                week.map((weekDay: Date) => {
                                        if (value) {
                                            const dayInfo: DayInfo | undefined = value.days[getDate(weekDay)]
                                            return <CalendarMatrixBodyCell key={weekDay.toString()} dayInfo={dayInfo}
                                                                           day={weekDay}/>
                                        } else {
                                            return <CalendarMatrixBodyCell key={weekDay.toString()} dayInfo={undefined}
                                                                           day={weekDay}/>

                                        }
                                    }
                                )
                            }
                        </tr>
                        {extraRow}
                    </Fragment>
                )
            })
        }
        </tbody>
    )
}

export default CalendarMatrixBody