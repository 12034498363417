import React, {ReactElement} from "react";

import img1 from './../../../assets/images/P1000014.jpg'
import img2 from './../../../assets/images/P1000015.jpg'
import img3 from './../../../assets/images/P1000016.jpg'
import img4 from './../../../assets/images/P1000017.jpg'
import img5 from './../../../assets/images/P1000019.jpg'
import img6 from './../../../assets/images/P1000021.jpg'
import img7 from './../../../assets/images/P1000022.jpg'
import img8 from './../../../assets/images/P1000023.jpg'
import img9 from './../../../assets/images/P1000025.jpg'
import {css} from "@emotion/css";

const ImageCss: Record<string, string> = {
    width: "300px"
}

const CardCss: Record<string, string> = {
    paddingBottom: '5px'
}


const Photos = (): ReactElement => {
    const colCss = 'col s12 m6 l4 xl'
    return (
        <div className="section white">
            <div className="row container center">
                <h4 className="header">Foto's</h4>
                <div className="row">
                    <div className={colCss}>
                        <div className={css(CardCss) + " "}><img className={css(ImageCss) + " responsive-img"} alt="1"
                                                                 src={img8}/></div>
                    </div>
                    <div className={colCss}>
                        <div className={css(CardCss) + " "}><img className={css(ImageCss) + " responsive-img"} alt="1"
                                                                 src={img9}/></div>
                    </div>
                    <div className={colCss}>
                        <div className={css(CardCss) + " "}><img className={css(ImageCss) + " responsive-img"} alt="1"
                                                                 src={img1}/></div>
                    </div>
                    <div className={colCss}>
                        <div className={css(CardCss) + " "}><img className={css(ImageCss) + " responsive-img"} alt="1"
                                                                 src={img2}/></div>
                    </div>
                    <div className={colCss}>
                        <div className={css(CardCss) + " "}><img className={css(ImageCss) + " responsive-img"} alt="1"
                                                                 src={img4}/></div>
                    </div>
                    <div className={colCss}>
                        <div className={css(CardCss) + " "}><img className={css(ImageCss) + " responsive-img"} alt="1"
                                                                 src={img5}/></div>
                    </div>
                    <div className={colCss}>
                        <div className={css(CardCss) + " "}><img className={css(ImageCss) + " responsive-img"} alt="1"
                                                                 src={img6}/></div>
                    </div>
                    <div className={colCss}>
                        <div className={css(CardCss) + " "}><img className={css(ImageCss) + " responsive-img"} alt="1"
                                                                 src={img7}/></div>
                    </div>
                    <div className={colCss}>
                        <div className={css(CardCss) + " "}><img className={css(ImageCss) + " responsive-img"} alt="1"
                                                                 src={img3}/></div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Photos