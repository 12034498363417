import {addDays, differenceInCalendarWeeks, endOfMonth, startOfMonth, startOfWeek} from "date-fns";
import {useEffect, useState} from "react";

const calendarMatrix = (date: Date): Date[][] => {
    const firstDay: Date = startOfMonth(date);
    const lastDay: Date = endOfMonth(date);
    const startDate: Date = startOfWeek(date, {weekStartsOn: 1});
    const matrixRows: number = differenceInCalendarWeeks(lastDay, firstDay, {weekStartsOn: 1}) + 1;
    const matrixColumns: number = 7;
    const totalDays: number = matrixRows * matrixColumns;

    const days: Date[] = Array.from<Date>({length: totalDays})
        .map((value: Date, index: number) => addDays(startDate, index))

    const calendar: Date[][] = Array.from<Date[]>({length: matrixRows})
    calendar.forEach((value: Date[], index: number) => calendar[index] = days.slice(index * matrixColumns, index * matrixColumns + matrixColumns))
    return calendar
}


const useCalendarMatrix = (date: Date): [Date[][]] => {
    let [matrix, setMatrix] = useState<Date[][]>(calendarMatrix(new Date(date.getFullYear(), date.getMonth(), 1)))
    useEffect(() => {
        setMatrix(calendarMatrix(new Date(date.getFullYear(), date.getMonth(), 1)))
    }, [date])
    return [matrix]
}

export default useCalendarMatrix