import React, {FC} from "react";
import Section from "../../components/section/section";
import Title from "../../components/title/title";
import SubTitle from "../../components/title/subtitle";
import Contact from "../../components/contact/contact";

const Intro: FC = () => {
    return (
        <Section>
            <Title/>
            <SubTitle/>
            <Contact/>
        </Section>
    )
}

export default Intro