import React, {FC} from "react";
import Section from "../../components/section/section";
import SectionTitle from "../../components/title/sectiontitle";
import Row from "../../components/grid/row";
import General from "./details/general";
import Rooms from "./details/rooms";
import Pricing from "./details/pricing";

const Info: FC = () => {
    return (
        <Section>
            <SectionTitle title="Info"/>
            <Row>
                <General/>
                <Rooms/>
                <Pricing/>
            </Row>
        </Section>
    )
}

export default Info